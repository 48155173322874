import { Component } from 'preact';
import { Router, route } from 'preact-router';
import { Link } from 'preact-router/match';
import './style';
// import Autocomplete from '/components/Autocomplete';
// import Home from '/components/Home';
import About from '/components/About';
import CardCreator from '/components/CardCreator';
import CardViewer from '/components/CardViewer';
import CardNavigator from '/components/CardNavigator';
import CardReview from '/components/CardReview';

export default class App extends Component {
  state = {
    error: null,
    currentPage: { page: null, pages: null, perPage: null, ids: [] },
    idsByPage: {}
  }

  // componentWillUnmount () {
  //   window.onpopstate = undefined;
  // }

  async componentDidMount () {    
    // window.onpopstate = (event) => {
    //   console.log('onpopstate', event);

    //   const { cardId } = event.state;

    //   if (cardId) {
    //     this.setState({ page: 'view-card', cardId });
    //   }
    // };

    
    // const { pathname } = location;
    // let cardId;

    // if (pathname === '/cards' || pathname.startsWith('/cards/')) {
    //   const suffix = pathname.slice('/cards/'.length);
    //   let cardId = null;

    //   if (/\d+/.test(suffix)) {
    //     cardId = suffix;
    //   }
    // }
  }

  // xonUpdateList = ({ cardIds }) => {
  //   console.log('App onUpdateList', { cardIds });
  //   this.setState({ cardIds });
  // }

  // 
  onUpdatePages = (data) => {
    if (!data) {
      return;
    }

    const { idsByPage, currentPage } = this.state;
    const newPage = data.add;
    const pagination = data.pagination;

    if (newPage) {
      const { page, ids } = newPage;
      // idsByPage[newPage.page] = newPage;
      this.setState({ idsByPage: {...idsByPage, [page]: ids } });
      return;
    }

    if (pagination) {
      const { pages, perPage } = pagination;

      currentPage.pages = pages;
      currentPage.perPage = perPage;

      this.setState({ currentPage });
      return;
    }

    const { page, pages, perPage, ids } = data;

    const newState = {
      currentPage: { page, ids, pages: pages || this.state.currentPage.pages, perPage: perPage || this.state.currentPage.perPage }
    };

    // if (page <= pages) {
      this.setState({ ...newState, idsByPage: {...idsByPage, [page]: ids } });
    // }
  }

  render () {
    const { error, currentPage, idsByPage, pages, perPage } = this.state;

    // console.log('render app', this.state)

    return (
      <div>
        <header>
          <h1><Link href={`/`}>CardBard</Link></h1>
        </header>

        {
          error &&
          <div class="error">
            <pre>{ error }</pre>
          </div>
        }

        <nav>
          <Link href={`/`}>Create</Link>
          <Link href={`/cards`}>Most recent</Link>
          <Link href={`/about`}>About</Link>
        </nav>

        <main>
          <Router>
            <CardCreator path="/" />
            <CardCreator path="/creating/:cardId?" />
            <CardNavigator
              path="/cards/:cardId?"
              currentPage={ currentPage }
              idsByPage={ idsByPage }
              onUpdate={ this.onUpdatePages }
            />
            <About path="/about" />
          </Router>
        </main>
      </div>
    );
  }
}
