export function wait (delay) {
  return new Promise(resolve => setTimeout(resolve, delay));
}

export async function fetchJSON (url, { method = 'GET', headers = {}, body } = {}) {
  headers['Accept'] = 'application/json';
  headers['Content-Type'] = 'application/json';

  if (body && typeof body !== 'string') {
    body = JSON.stringify(body);
  }

  try {
    const response = await fetch(url, { method, headers, body });

    // console.log('fetchJSON', url, response.status);
    
    return await response.json();
  }

  catch (err) {
    console.error('fetchJSON error', url, err);
  }
}
