const API_SERVER = process.env.NODE_ENV === 'development' ?
  'http://localhost:8123' : '';

const MEDIA_ENDPOINT = `${API_SERVER}/media`;

const API_ENDPOINTS = {
  create: '/api/create',
  progress: '/api/progress',
  latest: '/api/latest',
  pages: '/api/pages',
  pagination: '/api/pagination',
  predictions: '/api/predictions'
};

Object.keys(API_ENDPOINTS)
.forEach(key => API_ENDPOINTS[key] = API_SERVER + API_ENDPOINTS[key]);

export {
  MEDIA_ENDPOINT,
  API_ENDPOINTS
};
