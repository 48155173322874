import { Component } from 'preact';
import StaggerText from '/components/StaggerText';
import ReviewControls from '/components/ReviewControls';
import { wait } from '/utils.js';
import { MEDIA_ENDPOINT } from '/config.js';

const IMAGE_RELOAD_DELAY = 1000;

export default class Card extends Component {
  state = {
    imageLoaded: false,
    imageError: false
  }

  setDocumentTitle () {
    const { prediction } = this.props;

    if (prediction.title) {
      const title = `"${prediction.title}" - CardBard - AI xmas card maker`;

      if (title !== document.title) {
        document.title = title;
      }
    }
  }

  componentDidMount () {
    this.setDocumentTitle();
  }

  componentDidUpdate (prevProps) {
    if (this.props.prediction !== prevProps.prediction) {
      this.setState({ imageLoaded: false });
    }

    this.setDocumentTitle();
  }

  componentWillUnmount () {
    document.title = `CardBard - AI xmas card maker`;
  }

  reloadImage = async ({ target }) => {
    if (!this.imageLoadAttempts) {
      this.imageLoadAttempts = 0;
    }

    else if (this.imageLoadAttempts >= 5) {
      this.onImageError();
      return;
    }

    await wait(IMAGE_RELOAD_DELAY);

    this.imageLoadAttempts ++;
    target.src = target.src;
  }

  onImageLoad = () => {
    this.setState({ imageLoaded: true });
  }

  onImageError = () => {
    this.setState({ imageError: true });
  }

  render () {
    const { prediction, fetching } = this.props;
    const { imageLoaded } = this.state;

    return (
      <div class="Card">
        <article>
          <main>
          {
            prediction && prediction.message &&
            <blockquote class="message">
              <pre><StaggerText>{ prediction.message }</StaggerText></pre>
            </blockquote>
          }

          {
            prediction && prediction.images &&
            <div class="images">
              {
                prediction.images.map(({ src, predictionId, modeName, model }, i) =>
                  [
                    <figure>
                      <img alt={imageLoaded && prediction.altText || ''} src={!fetching && `${MEDIA_ENDPOINT}/${src}`} style={ fetching ? 'opacity: 0.2;' : undefined } onLoad={ this.onImageLoad } onError={ this.reloadImage } />
                      <figcaption>{ prediction.title }</figcaption>
                    </figure>,

                    <ReviewControls cardId={ prediction.id } imageId={ predictionId } />
                  ]
                )
              }
            </div>
          }
          </main>

          {/*
            false && prediction && Object.keys(prediction).map(key => {
              if (key === 'message') {
                return;
              }

              const value = prediction[key];

              return (
                <div>
                  <strong><code>{ key }</code></strong>
                  <pre>{ JSON.stringify(value, null, 2).replace(/[\\]+n/g, '\n') }</pre>
                </div>
              );
            })
          */}
        </article>
      </div>
    );
  }
}
