import { Component } from 'preact';
import { Link } from 'preact-router/match';
import { route } from 'preact-router';
import { fetchJSON } from '/utils.js';

const API_REVIEW = `/api/review`;

export default class ReviewControls extends Component {
  state = {
    waiting: false,
    error: null,
    rating: null
  }

  componentDidUpdate (prevProps) {
    if (prevProps.cardId !== this.props.cardId || prevProps.imageId !== this.props.imageId) {
      this.setState({ waiting: false, error: null, rating: null });
    }
  }

  shouldComponentUpdate (nextProps, nextState) {
    if (!nextState.error && (!this.state.rating && !nextState.rating)) {
      return false;
    }

    return true;
  }

  async onRate (rating) {
    const { cardId, imageId } = this.props;

    this.setState({ waiting: true, error: null, rating });
    
    try {
      const response = await fetchJSON(`${API_REVIEW}/${cardId}`, { method: 'POST', body: { imageId, rating } });
      this.setState({ waiting: false, error: response.error && response.statusText || null });
    }

    catch (err) {
      this.setState({ waiting: false, error: err.message });
    }
  }

  onThumbsUp = () => {
    this.onRate(true);
  }

  onThumbsDown = () => {
    this.onRate(false);
  }

  render () {
    const { waiting, rating, error } = this.state;
    const hasRating = typeof rating === 'boolean';

    // console.log('ReviewControls render', this.state);

    return (
      <aside class="review">
        <div class="controls">
          <fieldset data-rating={ hasRating ? rating : undefined } >
            <legend>{ rating === true ? 'Thanks' : '' }{ rating === false ? 'OK, thanks' : '' }</legend> 
            <button class="yes" disabled={ waiting || hasRating || undefined } onClick={ this.onThumbsUp } >👍</button>
            <button class="no" disabled={ waiting || hasRating || undefined } onClick={ this.onThumbsDown }>👎</button>
          </fieldset>

          { error && <p class="error">{ error }</p> }
        </div>
      </aside>
    );
  }
}
