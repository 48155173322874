import { Component } from 'preact';
import { Link } from 'preact-router/match';
import StaggerText from '/components/StaggerText';

export default class About extends Component {
  render () {
    return (
      <div>
        <p><Link href="/">CardBard</Link> uses AI models to interpret your prompt – to make it Christmassy and artistic – and to create a Christmas card with a piece of verse and a picture.</p>

        <p>It uses the text model <a href="https://replicate.com/tomasmcm/zephyr-7b-beta" rel="external" target="_blank">Zephyr 7B β</a> and the image model <a href="https://replicate.com/playgroundai/playground-v2-1024px-aesthetic" rel="external" target="_blank">Playground v2 – Aesthetic</a>, which are hosted on <a href="https://replicate.com" rel="external" target="_blank">Replicate</a>.</p>

        <p>Made by <a href="https://twitter.com/premasagar" rel="external" target="_blank">Prem</a>.</p>

        <p><StaggerText>{`Enjoy playing, and thanks for dropping by. Please share with your friends.`}</StaggerText></p>
      </div>
    );
  }
}
