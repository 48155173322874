import { Component } from 'preact';
import Card from '/components/Card';
import { fetchJSON } from '/utils.js';
import { API_ENDPOINTS } from '/config.js';

export default class CardViewer extends Component {
  state ={
    prediction: null,
    error: null,
    fetching: false
  }

  async fetchCard () {
    this.setState({ fetching: true });

    const { cardId } = this.props;
    const url = `${API_ENDPOINTS.predictions}/prediction-${cardId}.json`;

    try {
      const prediction = await fetchJSON(url);

      if (prediction) {
        this.setState({ prediction, fetching: false });

        if (this.props.onLoad) {
          this.props.onLoad({ page: prediction.page });
        }
      }

      else {
        throw new Error(`Not found, ${cardId}`);
      }
    }

    catch (err) {
      console.error('CardViewer', err);
      this.setState({ error: err, fetching: false });
    }
  }

  async componentDidMount () {
    this.fetchCard();
  }

  async componentDidUpdate (prevProps) {
    if (prevProps.cardId !== this.props.cardId) {
      // console.log('componentDidUpdate', { prevProps }, this.props);
      this.fetchCard();
    }
  }

  render () {
    const { prediction, error, fetching } = this.state;

    return (
      <div class="CardViewer">
        {
          prediction && !fetching && 
          <Card prediction={ prediction } fetching={ false } />
        }
        {
          error &&
          <div class="error">
            <pre>{ error.message }</pre>
          </div>
        }
      </div>
    );
  }
}
