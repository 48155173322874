import { Component } from 'preact';
import { route } from 'preact-router';
import { Link } from 'preact-router/match';
import { wait, fetchJSON } from '/utils.js';
import Card from '/components/Card';
import StaggerText from '/components/StaggerText';
import { API_ENDPOINTS } from '/config.js';

const TAKING_A_WHILE = 20000;
const TAKING_A_LONG_TIME = 80000;

export default class CardCreator extends Component {
  refs = {}
  state = {
  	prediction: null,
  	waiting: false,
  	submitted: false,
  	error: null,
  	progress: [],
  	prompt: null,
  	dialogue: [],
  	currentDialogue: 1,
  	completion: 0
  }

  componentDidMount () {
    const { cardId } = this.props;
  	// console.log('CardCreator mount', { cardId }, this.props);

  	if (cardId && !this.eventSource) {
  		this.eventSource = this.createEventSource(cardId);
  	}

  	document.title = `CardBard - AI xmas card maker`;
  }

  componentWillUnmount () {
    const { cardId } = this.props;
  	// console.log('CardCreator unmount', { cardId }, this.props);

  	if (this.eventSource) {
  		this.eventSource.close();
  		delete this.eventSource;
  	}

  	clearTimeout(this.timer);
  }

  componentDidUpdate (prevProps, prevState) {
  	const { cardId } = this.props;
  	const { currentDialogue } = this.state;
  	// console.log('CardCreator update', { prevProps, prevState }, this.props, this.state);

  	if (cardId && prevProps.cardId !== this.props.cardId && !this.eventSource) {
  		this.eventSource = this.createEventSource(cardId);
  	}

  	if (currentDialogue !== prevState.currentDialogue) {
  		scrollTo(0, document.body.scrollHeight - innerHeight + 100);
  	}
  }

  createEventSource (predictionId) {
  	const progressSSE = new EventSource(`${API_ENDPOINTS.progress}/${predictionId}`);
  	const startAt = Date.now();
  	let completion = 0;

  	// console.log('createEventSource', { predictionId });

	  const progressListener = (event) => {
    	const { progress, dialogue } = this.state;
			let data = JSON.parse(event.data);
			
			if (!Array.isArray(data)) {
				data = [data];
			}

			// console.log('progress', data);

			data.forEach(d => {
				if (d.createdAt) {
					this.timer = setTimeout(() => {
						const waitingForOutput = { timer: TAKING_A_WHILE };
						const { progress, dialogue } = this.state;

						progress.push(waitingForOutput);
						dialogue.push(this.updateDialogue([ waitingForOutput ]));

						this.setState({ progress, dialogue });

						this.timer = setTimeout(() => {
							const waitingForOutput = { timer: TAKING_A_LONG_TIME };
							const { progress, dialogue } = this.state;

							progress.push(waitingForOutput);
							dialogue.push(this.updateDialogue([ waitingForOutput ]));

							this.setState({ progress, dialogue });
						}, TAKING_A_LONG_TIME - TAKING_A_WHILE);
					}, TAKING_A_WHILE);
				}

				if (d.imageText) {
					clearTimeout(this.timer);
				}

				if (d.type === 'image' && d.completion) {
					completion = d.completion;
				}

				if (d.end === true) {
					progressSSE.close();
					clearTimeout(this.timer);

					// Re-opended on changed url to creating page of an existing card; not submitted this session
					if (!this.state.submitted && d.exists === true) {
						route(`/cards/${predictionId}`, true);
						return;
					}

					history.pushState({}, '', `/cards/${predictionId}`);
				}

				else if (d.error === true) {
					progressSSE.close();
					clearTimeout(this.timer);

					// Refreshing an old or invalid creating page
					if (d.status === 404) {
						route(`/`, true);
						return;
					}
				}

				progress.push(d);
			});

			dialogue.push(...this.updateDialogue(data));

			this.setState({ progress, dialogue, completion });
		};

		progressSSE.addEventListener('message', progressListener);

		progressSSE.addEventListener('error', err => {
			const { progress } = this.state;

			progress.push({ error: true, status: 400, statusText: 'Bad request' });
			this.setState({ progress });
			route(`/`, true);
		});

	  return progressSSE;
	}

	updateDialogue (data) {
    const { progress, dialogue, prompt } = this.state;
    const newDialogue = [];
    
    data.forEach(({ createdAt, message, title, imageText, end, error, timer }) => {
    	let text = '';

	    if (createdAt) {
	    	text += `OK, I'm going to think about what to make. Hold on.`;
	    }

	    if (timer === TAKING_A_WHILE) {
	    	text += `I need a little time, to think it over.`;
	    }

	    if (timer === TAKING_A_LONG_TIME) {
	    	text += `Once I've warmed up, I'm quite fast. But I'm just waking up. Stand by, I'll be back in a minute.`;
	    }

	    if (imageText) {
	    	text += `I have an idea for a picture:\n\n"${imageText}"`;
	    }

	    if (message) {
	    	const indentedText = '    ' + message.split('\n').join('\n    ');
	    	text += `I've written a little verse:\n\n${indentedText}`;
	    }

	    if (title) {
	    	text += `I'll call the picture "${title}".`;
	    }
			
			if (error) {
				text += `Oh, there was an error. I think you'll need to try again.`;
			}

			if (end) {
				text += `It's ready now. You can share it with your loved ones (copy the link in the address bar).`;
			}

			if (text) {
				newDialogue.push(text);
			}
    });

    // console.log('update dialogue', { data, progress, dialogue, newDialogue });

		return newDialogue;
	}

  onSubmit = async event => {
    event.preventDefault();

    const text = this.refs.text.value;

    this.setState({ progress: [], dialogue: [], currentDialogue: 1, submitted: true, prompt: text });
    // console.log('onSubmit start', { text }, (new Date()).toISOString());

    try {
	    const response = await fetchJSON(API_ENDPOINTS.create, { method: 'POST', body: { text } });

	    if (!response || !response.predictionId) {
	    	console.error('onSubmit error', response);
	    	this.state.progress.push({ error: true, status: 408, statusText: 'Request timeout' });
		  	this.setState({ progress });
	    }

	    const { predictionId, error } = response;

	    if (error) {
		  	this.state.progress.push({ error: true, status: error.status, statusText: error.statusText });
		  	this.setState({ progress });
	    }

	    else if (predictionId) {
		    route(`/creating/${predictionId}`);
		   }
	   }

	  catch (err) {
		 	console.error('onSubmit error', (new Date()).toISOString(), { text }, err);
		  // this.setState({ waiting: false });
		  
		  this.state.progress.push({ error: true, status: 500, statusText: err.message });

		  this.setState({ progress });
	 	}
  }

  render () {
  	const { showLabel } = this.props;
  	const { prediction, progress, dialogue, currentDialogue, submitted, completion } = this.state;

  	const latestProgress = progress.length && progress[progress.length - 1];
  	const isEnd = latestProgress && latestProgress.end || false;
  	const isError = latestProgress && latestProgress.error;
  	const waiting = (latestProgress || submitted) && !isEnd && !isError;
  	const cardId = this.props.cardId;
  	const cardUrl = `/cards/${cardId}`;

  	// const params = [ 'altText', 'title', 'imageText', 'images' ];

  	// const { altText, title, imageText, images } = progress.reduce((result, data) => {
  	// 	params.forEach(param => {
		// 		if (param in result) {
  	// 			return;
		// 		}

  	// 		if (param in data) {
  	// 			result[param] = data[param];
  	// 		}
  	// 	});

  	// 	return result;
  	// }, {});

  	// console.log('CardCreator render', { prediction, progress, waiting, submitted, currentDialogue, dialogue });

  	return (
    	<div class="CardCreator">
    	  { (!submitted || !progress.length || isError) && !this.eventSource && !cardId &&
	    		<form onSubmit={ this.onSubmit }>
	    			{ showLabel !== false && 
		    			<p>
		    				<label for="user-prompt">
		    					<StaggerText>{`I am Christmas card-making AI machine.\n\nGive me a theme or concept to work with. Keep it simple, or tell me about the characters, imagery and emotions you want to capture, perhaps an art style or artist. Then sit back and enjoy.`}</StaggerText>
		    				</label>
		    			</p>
		    		}
			      
			      <textarea id="user-prompt" required ref={ el => this.refs.text = el } autoFocus placeholder="Tell me about it. Anything at all.">
							</textarea>

						<p>
							<button type="submit" disabled={ waiting }>Create</button>
						</p>
					</form>
			  }

			  {
			  	(submitted || !isEnd) && dialogue.slice(0, currentDialogue).map(text => {
			  		return (
				  		<StaggerText onComplete={() => {
				  			let increment = 1;

				  			this.setState({ currentDialogue: currentDialogue + increment});
				  		}}>
				  			{ text }
				  		</StaggerText>
				  	);
				  })
			  }

			  {
			  	completion > 0 && (!isEnd && !isError) && currentDialogue === dialogue.length + 1 &&
			  	<pre class="completion">
			  		Progress: {`[${''.padStart(Math.round(completion / 10), '==')}]`}
			  	</pre>
			  }

			  {
			  	isEnd && currentDialogue === dialogue.length + 1 &&
			  	<div class="card-complete">
			  		<Link class="button" href={ cardUrl }>See your card</Link>
			  	</div>
			  }

			  {/*
			  	progress.map(item => {
			  		if (item.error) {
			  			return <p><pre class="error">{`Error: ${item.status} ${item.statusText}`}</pre></p>
			  		}

			  		if (item.type === 'image' || item.altText) {
		  				return;
		  			}

			  		const keys = Object.keys(item);

			  		return keys.map(key => {
			  			console.log({key, item});

			  			return (
				  			<div>
				  				<p><strong>{ key }</strong>: {
				  					typeof item[key] === 'object' ?
				  						( <pre>{ JSON.stringify(item[key], null, 2) }</pre> ) :
				  						( <pre>{ item[key] }</pre> )
				  					}</p>
				  			</div>
				  		);
			  		});
			  	})
			  */}

				{/* prediction &&
					<Card prediction={ prediction } />
				*/}
			</div>
    );
  }
}
