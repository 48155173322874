import { Component } from 'preact';
import { Link } from 'preact-router/match';
import { route } from 'preact-router';
import CardViewer from '/components/CardViewer';
import { fetchJSON } from '/utils.js';
import { API_ENDPOINTS } from '/config.js';

export default class CardNavigator extends Component {
  state = {
    currentCardId: null,
    page: null,
    error: null
  }

  // async updateCard (newCardIds, newCardId) {
  //   const { cardId } = this.props;
  //   const cardIds = newCardIds || this.state.currentPage.ids;

  //   let currentCardId;

  //   if (cardIds.length) {
  //     if (cardId && cardIds.includes(cardId)) {
  //       currentCardId = cardId;
  //     }

  //     else {
  //       if (cardId) {
  //         // const latestCardIds = await this.updateList();
  //         const currentPage = await this.updatePages();
  //         const latestCardIds = currentPage.ids;

  //         if (latestCardIds.includes(cardId)) {
  //           // Latest card ids;
  //           return await this.updateCard(latestCardIds);
  //         }

  //         console.log('cardId not found in cardIds', { cardId, newCardId, currentCardId, cardIds });

  //         if (newCardId) {
  //           currentCardId = cardId;
  //           cardIds.push(cardId);
  //         }

  //         else {
  //           currentCardId = cardIds[cardIds.length - 1];
  //         }
  //       }

  //       else {
  //         currentCardId = cardIds[cardIds.length - 1];
  //       }

  //       console.log('>> route to current card', { cardId, currentCardId }, this.props);
  //       route(`/cards/${currentCardId}`);
  //     }
  //   }

  //   else if (cardId && cardId !== this.state.currentCardId) {
  //     currentCardId = cardId;
  //   }

  //   if (newCardIds && newCardIds.length && newCardIds !== this.state.cardIds) {
  //     console.log('New cardids', { newCardIds });
  //     this.setState({ cardIds: newCardIds, currentCardId });
  //   }

  //   else if (currentCardId !== this.state.currentCardId) {
  //     this.setState({ currentCardId });
  //   }

  //   else {
  //     console.log('UPDATE CARD, no newCardIds, no currentCardId change');
  //   }
  // }

  // async xupdatePages () {
  //   let currentPage;

  //   try {
  //     // { page, pages, perPage, ids }
  //     currentPage = await fetchJSON(API_ENDPOINTS.latest);

  //     const cardIds = currentPage.ids;

  //     this.updateCard(cardIds);

  //     if (this.props.onUpdate) {
  //       this.props.onUpdate(currentPage);
  //     }
  //   }

  //   catch (err) {
  //     console.error('get latest', err);
  //     this.setState({ error: err });
  //   }

  //   return currentPage;
  // }

  // async XupdateList () {
  //   let cardIds;

  //   try {
  //     cardIds = await fetchJSON(API_LIST);

  //     if (Array.isArray(cardIds)) {
  //       this.updateCard(cardIds);

  //       if (this.props.onUpdate) {
  //         this.props.onUpdate({ cardIds });
  //       }
  //     }
  //   }

  //   catch (err) {
  //     console.error('get cards', err);
  //     this.setState({ error: err });
  //   }

  //   return cardIds;
  // }

  // async componentDidMount () {
  //   const { cardId, currentPage } = this.props;
  //   const cardIds = currentPage.ids;

  //   if (cardIds && cardIds.length) {
  //     this.updateCard(cardIds);
  //     return;
  //   }

  //   if (cardId) {
  //     this.setState({ currentCardId: cardId });
  //   }

  //   // await this.updateList();
  //   await this.updatePages();
  // }

  async componentDidMount () {
    const { cardId, currentPage, idsByPage } = this.props;
    const cardIds = currentPage.ids || [];
    const currentCardId = cardId || cardIds[cardIds.length - 1];

    try {
      if ((!currentCardId && !currentPage.page) || currentPage.page && currentPage.page === currentPage.pages && !cardIds.includes(currentCardId)) {
        // { page, pages, perPage, ids }
        const currentPage = await fetchJSON(API_ENDPOINTS.latest);

        if (this.props.onUpdate) {
          this.props.onUpdate(currentPage);
        }
      }
    }

    catch (err) {
      console.error('componentDidMount', err);
      this.setState({ error: err });
    }

    this.setState({ currentCardId });

    // await this.updateList();
    // await this.updatePages();
  }

  async componentDidUpdate (prevProps, prevState) {
    const { cardId, idsByPage } = this.props;
    const { page } = this.state;
    const currentPage = this.props.currentPage || { page, pages: null, perPage: null, ids: idsByPage[page] };

    const cardIds = currentPage && currentPage.ids || [];
    let currentCardId = cardId || (currentPage.pages && currentPage.page === currentPage.pages && cardIds[cardIds.length - 1]);

    // console.log('CardNavigator componentDidUpdate', { currentCardId, prevProps, props: this.props, prevState, state: this.state, isNewCard: this.props.cardId !== prevProps.cardId, isSameProps: JSON.stringify(prevProps) === JSON.stringify(this.props), isSameState: JSON.stringify(prevState) === JSON.stringify(this.state) });

    if (!this.props.cardId) {
      if (currentCardId) {
        // this.setState({ page: null });
        route(`/cards/${currentCardId}`, true);
      }

      const currentPage = await fetchJSON(API_ENDPOINTS.latest);

      if (currentPage && currentPage.ids && currentPage.ids.length) {
        if (currentPage && this.props.onUpdate) {
          this.props.onUpdate(currentPage);
        }
      }

      return;
    }

    if (this.props.currentPage.page && this.props.currentPage.page !== prevProps.currentPage.page && page !== this.props.currentPage.page) {
      currentCardId = this.props.currentPage.ids.slice(-1)[0];
      // this.setState({ currentCardId, page: this.props.currentPage.page });
      route(`/cards/${currentCardId}`);
      return;
    }

    if (currentCardId && (currentCardId !== this.state.currentCardId)) {
      this.setState({ currentCardId });
    }

    if (page !== prevState.page) {
      if (page && (!(page in idsByPage) || (currentPage.perPage && idsByPage[page].ids && idsByPage[page].ids.length < currentPage.perPage) || !cardIds.includes(currentCardId))) {
        try {
          let newPage;

          if (!currentPage.pages) {
            // Check if pages needed; { pages, perPage }
            const pagination = await fetchJSON(API_ENDPOINTS.pagination);

            if (this.props.onUpdate) {
              this.props.onUpdate({ pagination });
            }

            if (page === pagination.pages) {
              newPage = await fetchJSON(API_ENDPOINTS.latest);
            }

            else {
              const filename = `page-${String(page).padStart(4, '0')}.json`;
              const ids = await fetchJSON(`${API_ENDPOINTS.pages}/${filename}`);
              newPage = { page, ids };
            }
          }

          else if (currentPage.pages && page <= currentPage.pages) {
            const filename = `page-${String(page).padStart(4, '0')}.json`;
            const ids = await fetchJSON(`${API_ENDPOINTS.pages}/${filename}`);
            newPage = { page, ids };
          }

          if (this.props.onUpdate) {
            this.props.onUpdate(newPage);
          }
        }

        catch (err) {
          console.error('componentDidUpdate', err);
          this.setState({ error: err });
        }
      }
    }

    // card is last on page; get next page
    if (page && cardIds.length > 1 && currentCardId === cardIds[cardIds.length - 1] && !(page + 1 in idsByPage) && (!currentPage.pages || page <= currentPage.pages)) {
      const nextPage = page + 1;

      try {
        let newPage;

        // Check if pages needed; { page, pages, perPage }
        if (currentPage.pages && nextPage === currentPage.pages) {
          newPage = await fetchJSON(API_ENDPOINTS.latest);
        }

        else {
          if (currentPage.pages) {
            if (nextPage > currentPage.pages) {
              return;
            }
          }

          else {
            const pagination = await fetchJSON(API_ENDPOINTS.pagination);

            if (this.props.onUpdate) {
              this.props.onUpdate({ pagination });
            }

            if (nextPage > pagination.pages) {
              return;
            }
          }

          const filename = `page-${String(nextPage).padStart(4, '0')}.json`;
          const ids = await fetchJSON(`${API_ENDPOINTS.pages}/${filename}`);
          newPage = { page: nextPage, ids };
        }

        if (this.props.onUpdate) {
          this.props.onUpdate({ add: newPage });
        }
      }

      catch (err) {
        console.error('nextCardId', err);
        this.setState({ error: err });
      }
    }

    // card is first on page; get previous page
    else if (page > 1 && currentCardId === cardIds[0] && !(page - 1 in idsByPage)) {
      const prevPage = page - 1;

      try {
        const filename = `page-${String(prevPage).padStart(4, '0')}.json`;
        const ids = await fetchJSON(`${API_ENDPOINTS.pages}/${filename}`);
        const newPage = { page: prevPage, ids };

        if (this.props.onUpdate) {
          this.props.onUpdate({ add: newPage });
        }
      }

      catch (err) {
        console.error('nextCardId', err);
        this.setState({ error: err });
      }
    }

    // console.log('CardNavigator componentDidUpdate', { prevProps, props: this.props, prevState, state: this.state, isNewCard: this.props.cardId !== prevProps.cardId, isSameProps: JSON.stringify(prevProps) === JSON.stringify(this.props), isSameState: JSON.stringify(prevState) === JSON.stringify(this.state) });

    // if (!this.props.cardId) {
    //   // await this.updateList();
    //   await this.updatePages();
    // }

    // else if (this.props.cardId !== prevProps.cardId) {
    //   await this.updateCard();
    // }
  }

  // async componentDidUpdate (prevProps, prevState) {
  //   console.log('CardNavigator componentDidUpdate', { prevProps, props: this.props, prevState, state: this.state, isNewCard: this.props.cardId !== prevProps.cardId, isSameProps: JSON.stringify(prevProps) === JSON.stringify(this.props), isSameState: JSON.stringify(prevState) === JSON.stringify(this.state) });

  //   if (!this.props.cardId) {
  //     // await this.updateList();
  //     await this.updatePages();
  //   }

  //   else if (this.props.cardId !== prevProps.cardId) {
  //     await this.updateCard();
  //   }
  // }

  shouldComponentUpdate (nextProps, nextState) {
    const renderProps = ['cardId', 'idsByPage'];
    const renderState = ['currentCardId', 'error', 'page'];
    const pageProps = ['page', 'pages', 'perPage', 'ids' ];

    // console.log('CardNavigator shouldComponentUpdate', { nextProps, props: this.props, nextState, state: this.state, isNewCard: this.props.cardId !== nextProps.cardId, isSameProps: JSON.stringify(nextProps) === JSON.stringify(this.props), isSameState: JSON.stringify(nextState) === JSON.stringify(this.state), isSamePage: pageProps.some(key => nextProps.currentPage[key] !==  this.props.currentPage[key]), changedProps: renderProps.map(key => nextProps[key] !== this.props[key] ? [key, this.props[key], nextProps[key]] : '').filter(s => !!s), changedState: renderState.map(key => nextState[key] !== this.state[key] ? [key, this.state[key], nextState[key]] : '').filter(s => !!s) });

    // if (nextState.cardId === '' && !!this.props.cardId) {
    //   const { currentPage } = this.props;
    //   const cardIds = currentPage.ids;

    //   if (this.props.cardId === cardIds[cardIds - 1]) {
    //     console.log('shouldComponentUpdate, route to latest card');
    //     route(`/cards/${this.props.cardId}`);

    //     return false;
    //   }

    //   // TD: update cardids?
    // }

    return renderProps.some(key => nextProps[key] !== this.props[key]) ||
      renderState.some(key => nextState[key] !== this.state[key]) ||
      pageProps.some(key => nextProps.currentPage[key] !==  this.props.currentPage[key]);
  }

  nextCardId () {
    const { currentPage, idsByPage } = this.props;
    const { currentCardId, page } = this.state;
    let cardIds = idsByPage[page];

    if (!cardIds || !cardIds.length) {
      return null;
    }

    let index = cardIds.indexOf(currentCardId);

    // console.log('nextCardId', { page, currentPage, idsByPage, currentCardId, index, cardIds });

    if (index === -1) {
      return null;
    }

    if (index === cardIds.length - 1) {
      cardIds = idsByPage[page + 1];

      if (cardIds && cardIds.length) {
        return cardIds[0];
      }

      else {
        return null;
      }
    }

    return cardIds[index + 1];
  }

  previousCardId () {
    const { currentPage, idsByPage } = this.props;
    const { currentCardId, page } = this.state;
    let cardIds = idsByPage[page] || currentPage.ids;

    if (!cardIds || !cardIds.length) {
      return null;
    }

    let index = cardIds.indexOf(currentCardId);

    // console.log('previousCardId', { page, currentPage, idsByPage, currentCardId, index, cardIds });

    if (index === -1) {
      return null;
    }

    if (index === 0) {
      cardIds = idsByPage[page - 1];

      if (cardIds && cardIds.length) {
        return cardIds[cardIds.length - 1];
      }

      else {
        return null;
      }
    }    

    return cardIds[index - 1];
  }

  // onClickLink = event => {
  //   event.preventDefault();

  //   const href = event.target.href;
  //   let cardId = event.target.getAttribute('data-card');

  //   if (cardId) {
  //     history.pushState({ cardId }, '', href);
  //     this.setState({ currentCardId: cardId });
  //   }

  //   else if (href.startsWith('/cards/')) {
  //     const { cardIds } = this.state;

  //     if (cardIds && cardIds.length) {
  //       cardId = cardIds[0];
  //     }

  //     history.pushState({ cardId }, '', href);
  //     this.setState({ currentCardId: cardId || null });
  //   }

  //   else {
  //     history.pushState({}, '', href);
  //     this.setState({ currentCardId: null });
  //   }
  // }

  render () {
    const { currentPage } = this.props;
    const { currentCardId, error } = this.state;
    const cardIds = currentPage.ids || [];
    const previousCardId = this.previousCardId();
    const nextCardId = this.nextCardId();

    // console.log('render CardNavigator', { currentCardId, numCards: cardIds.length }, this.props)

    return (
      <section>
        { cardIds &&
          <nav>
            <Link rel="prev" class={ !previousCardId && 'none' || undefined } href={previousCardId && `/cards/${previousCardId}`}>&lt; Previous</Link>
            <Link rel="next" class={ !nextCardId && 'none' || undefined } href={nextCardId && `/cards/${nextCardId}`}>Next &gt;</Link>
          </nav>
        }

        {
          error &&
          <div class="error">
            <pre>{ error }</pre>
          </div>
        }

        {
          currentCardId &&
          <CardViewer cardId={ currentCardId } onLoad={ ({ page }) =>  this.setState({ page }) } />
        }
      </section>
    );
  }
}
