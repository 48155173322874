import { Component } from 'preact';

const DELAY = 160;
const MIN_CHUNK_SIZE = 4;
const MAX_CHUNK_SIZE = 16;
const MEAN_CHUNK_SIZE = (MAX_CHUNK_SIZE - MIN_CHUNK_SIZE) / 2;

function _writeChunk (text, progressFn, completeFn, pointer = 0) {
  if (!text || pointer >= text.length) {
    return;
  }

  let chunkSize = Math.round(Math.random() * (MAX_CHUNK_SIZE - MIN_CHUNK_SIZE) + MIN_CHUNK_SIZE);

  // console.log({pointer, chunkSize, chunk: text.slice(pointer, pointer+ chunkSize)})
  
  if (text[pointer + chunkSize + 1] === ' ') {
    chunkSize += 1;
  }

  else if (text[pointer + chunkSize + 2] === ' ') {
    chunkSize += 2;
  }

  else if (text[pointer + chunkSize - 2] === ' ') {
    chunkSize -= 1;
  }

  const delay = (DELAY / MEAN_CHUNK_SIZE) * chunkSize;

  const result = progressFn(text.slice(pointer, pointer + chunkSize), { text });

  if (result === false) {
  	if (completeFn) {
  		completeFn();
  	}

    return;
  }

  pointer += chunkSize;

  if (pointer < text.length) {
    setTimeout(() => _writeChunk(text, progressFn, completeFn, pointer), delay);
  }

  else {
  	// console.log('Stagger complete', text, completeFn);
  	
  	if (completeFn) {
  		setTimeout(completeFn, DELAY);
  	}
  }
}

function stagger (text, progressFn, completeFn, delayStart = true) {
  // '[[2974,142],[4682,241],[6469,390],[8203,544],[10044,612]]'
  // '[[3294,150],[5018,312],[6857,448],[8733,508]]'
  
  if (delayStart) {
    progressFn('', { text });
    setTimeout(() => _writeChunk(text, progressFn, completeFn), DELAY);
  }

  else {
    _writeChunk(text, progressFn, completeFn);
  }
}

export default class StaggerText extends Component {
  refs = {}
  state = {
  	text: (Array.isArray(this.props.children) ? this.props.children[0] : this.props.children).trim(),
  	current: ''
  }

  componentDidMount () {
  	const { text } = this.state;

  	if (typeof text !== 'string') {
  		console.error('StaggerText', this.props.children);
  		throw new Error('StaggerText, not string');
  	}

  	stagger(text, this.onChunk, this.props.onComplete, this.props.delayStart);
  }

  componentDidUpdate (prevProps) {
  	const text = (Array.isArray(this.props.children) ? this.props.children[0] : this.props.children).trim();

  	if (text !== this.state.text) {
  		this.setState({ text, current: '' }, () => {
  			stagger(text, this.onChunk, this.props.onComplete, this.props.delayStart);
  		});
  	}
  }

  componentWillUnmount () {
  	this._unmounting = true;
  }

  onChunk = (chunk, { text }) => {
  	if (this._unmounting) {
  		return false;
  	}

  	if (text !== this.state.text) {
  		return false;
  	}

  	this.setState({ current: this.state.current + chunk});
  }

  render () {
  	const { current, text } = this.state;
		
    return (
    	<span class="stagger">
	    	<pre class="stagger-text">{ text }</pre>
    		<pre class="stagger-current">{ current }</pre>
    	</span>
    );
  }
}
